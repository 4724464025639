import React from "react"
import MainTemplate from 'templates/MainTemplate'
import SEO from 'components/SEO/seo'
import Posters from 'components/FreeMaterials/Posters/Posters';
import {StyledFreeMaterials, StyleWrapper, StyledInner, StyledTitile } from 'components/FreeMaterials/FreeMaterials.styled'



const FreeMaterialPage = () => (
<MainTemplate>
<SEO title="Poradniki metodyczne" />
    <StyledFreeMaterials>
        <StyleWrapper>

        <StyledTitile><h1><strong>Plakaty</strong></h1></StyledTitile>
            <StyledInner>
             <Posters/>
            </StyledInner>

        </StyleWrapper>
    </StyledFreeMaterials>

</MainTemplate>
)

export default FreeMaterialPage
